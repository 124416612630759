import { CardPaymentSource } from 'lib';

import { SavedCardSource } from './createPaymentSource';
import { Card, CardFunding } from './types';

export const VAULT_CARD: Card = {
  number: '{{cardNumber}}',
  cvc: '{{cardCvc}}',
  expiryDate: {
    expiryMonth: '{{expiryMonth}}',
    expiryYear: '{{expiryYear}}',
  },
};

export const toCardFunding = (funding: CardFunding): NonNullable<CardPaymentSource['funding']> =>
  funding === 'Credit' ? 'credit' : funding === 'Debit' ? 'debit' : funding === 'Prepaid' ? 'prepaid' : 'unknown';

export const toSavedCardPaymentSource = (
  cardPaymentSourceId: string,
  { last4, cardBin, brand, funding, expMonth, expYear, country }: SavedCardSource,
): CardPaymentSource => ({
  cardPaymentSourceId,
  last4,
  cardBin,
  brand: brand === 'Unknown' ? '' : brand,
  funding: toCardFunding(funding),
  expMonth,
  expYear,
  country,
});
