import { defaultHeaders } from 'app/api';
import { handleVaultSubmitError, toCardError } from 'lib/vault';
import { VgsCardError } from 'lib/vgs';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

import { CreatePayPlanOfferResponse } from './createPayPlanOffer';
import { ErrorResponse } from './types';

export type CardPaymentSource = {
  Card: {
    paymentSourceId: string;
    cvc?: string;
  };
};

export type BankTransferPaymentSource = {
  BankTransfer: {
    reference?: string;
  };
};

export type CreatePayPlanRequest = {
  termsAndConditionsAccepted: boolean;
  offerDetails: CreatePayPlanOfferResponse['offerDetails'];
  offerVerificationToken: string;
  paymentSource: CardPaymentSource | BankTransferPaymentSource;
  threeDsSessionId: string | null;
};

export type CreatePayPlanResponse = {
  paymentTokenId: string;
};

export const vaultCreatePayPlan = async (
  vault: Vault,
  token: string,
  payload: CreatePayPlanRequest,
): Promise<{
  response?: CreatePayPlanResponse;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const response = await vault
    .submit({
      path: '/payplans',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleVaultSubmitError)
    .then((res) =>
      handleVaultSubmitResponse<CreatePayPlanResponse, ErrorResponse>(res, ({ message, detail }) => detail || message),
    );

  return { response };
};
