export const STATE_ERROR_MESSAGE = 'Invalid checkout state';

export const CARD_ERROR_MESSAGE = 'Invalid card';

export const CREATE_PAYPLAN_ERROR_MESSAGE = 'Invalid payment plan';

export const GET_PAYPLAN_DATA_ERROR_MESSAGE = 'Invalid amount';

export const CUSTOMER_TOKEN_ERROR_MESSAGE = 'Invalid customer token';

export const FORM_VALIDATION_ERROR_MESSAGE = 'Please fix invalid fields';

export type ApiErrorCode =
  | 'account_disabled'
  | 'amount_too_large'
  | 'bad_gateway'
  | 'bad_request'
  | 'credentials_invalid'
  | 'checkout_approval_pending'
  | 'checkout_limit_breached'
  | 'charge_not_found'
  | 'currency_invalid'
  | 'customer_id_invalid'
  | 'customer_phone_number_does_not_match_record'
  | 'customer_email_does_not_match_record'
  | 'forbidden'
  | 'internal_error'
  | 'invalid_customer_verification_codes'
  | 'malformed_request'
  | 'merchant_account_suspended'
  | 'merchant_slug_invalid'
  | 'merchant_email_invalid'
  | 'merchant_id_invalid'
  | 'merchant_order_invalid'
  | 'merchant_transfer_not_found'
  | 'method_not_allowed'
  | 'not_found'
  | 'not_implemented'
  | 'order_state_invalid'
  | 'order_payment_method_unavailable'
  | 'payment_amount_mismatch'
  | 'payment_amount_invalid'
  | 'payment_data_invalid'
  | 'payment_state_invalid'
  | 'payment_source_invalid'
  | 'payment_token_already_used'
  | 'payment_method_tokenization_request_failed'
  | 'payment_not_found'
  | 'refund_amount_invalid'
  | 'transaction_invalid'
  | 'transaction_not_found'
  | 'unauthorized'
  | 'url_invalid'
  | 'invalid_remote_authority'
  | 'invalid_time_range'
  | 'unknown_charge_error'
  | 'do_not_honor'
  | 'expired_card'
  | 'fraudulent'
  | 'incorrect_cvc'
  | 'insufficient_funds'
  | 'invalid_cvc'
  | 'invalid_expiry_month'
  | 'invalid_expiry_year'
  | 'pickup_card'
  | 'processing_error'
  | 'stolen_card';

export interface ApiError {
  statusCode: number;
  errorCode: ApiErrorCode;
  message: string;
  tracer: string;
  detail: string;
  errorTypeDescription: string;
}

export function isApiError(error: any): error is ApiError {
  return error && error.statusCode !== undefined && error.errorCode !== undefined;
}

export const getApiErrorMessage = (error: ApiError): string => {
  // TODO Enable when 'invalid_customer_verification_codes' is implemented in API
  // if (error.errorCode === 'invalid_customer_verification_codes') return 'Invalid verification code';
  if (error.errorCode === 'malformed_request') return 'Invalid value';
  if (error.errorCode === 'payment_data_invalid' && error.detail) return error.detail;
  return error.message || 'Unknown error';
};

export const getErrorMessage = (error: any): string => {
  const isText = (text: any): boolean => typeof text === 'string' && !!text.length;
  if (isText(error)) return error;
  if (isApiError(error)) return getApiErrorMessage(error);
  if (isText(error?.message)) return error.message;
  if (isText(error?.reason)) return error.reason;
  return 'Unknown error';
};
