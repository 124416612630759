import { SendCustomerVerification } from 'app';
import { sessionCorrelationId } from 'app/api';
import {
  CheckoutStep,
  EventName,
  PaymentType,
  VgsCardError,
  getErrorMessage,
  toCardError,
  toCardPaymentSourceMessage,
  toErrorMessage,
  toEventMessage,
  toPaymentTokenMessage,
  toResizeMessage,
  useCardSubmit,
  useCheckoutContext,
  useCreatePayPlanData,
  useCreatePayPlanSubmit,
  useGetCustomerId,
  useMount,
  useMountVaultCard,
  usePayCardSubmit,
  usePayPlanCardSubmit,
  useSendEmailAndPhoneVerificationCodes,
  useSendMessage,
  useVerificationCodesSubmit,
} from 'lib';
import { CARD_ERROR_MESSAGE } from 'lib/errorMessages';
import React from 'react';

import { InitialPayment, Layout, PaymentOption, PlanReview, UserVerification } from './';
import { GoBackBtn, KycWrapper } from './Checkout.styles';
import { LayoutProps } from './Layout';
import { PaymentOptionProps } from './PaymentOption';

declare global {
  interface Window {
    LimepayIdentity: any;
  }
}

export interface CheckoutProps {
  checkoutRef?: React.MutableRefObject<{
    onMessage: (message: unknown) => void;
  } | null>;
}
export const Checkout: React.FC<CheckoutProps> = ({ checkoutRef }) => {
  const { state, update } = useCheckoutContext();
  const {
    params,
    initParams,
    config,
    paymentType,
    customerId,
    minFirstInstalmentAmount,
    emailVerificationCode,
    phoneVerificationCode,
    isTermsAgreed,
    currentUser,
    emailVerification,
    phoneVerification,
    payPlanOffer,
    cardPaymentSourceId,
    cardPaymentSources,
    checkoutStep,
    vaultCvc,
  } = state;

  const sendMessage = useSendMessage();
  const onMount = useMount();
  const onMountVaultCard = useMountVaultCard();
  const onCardSubmit = useCardSubmit();
  const onPayCardSubmit = usePayCardSubmit();
  const onGetCustomerId = useGetCustomerId();
  const onPayPlanCardSubmit = usePayPlanCardSubmit();
  const sendEmailAndPhoneVerificationCodes = useSendEmailAndPhoneVerificationCodes();
  const onVerificationCodesSubmit = useVerificationCodesSubmit();
  const onCreatePayPlanData = useCreatePayPlanData();
  const onCreatePayPlanSubmit = useCreatePayPlanSubmit();
  const onCardValid = React.useCallback(() => setCardError(null), []);

  const [pending, setPending] = React.useState(false);
  const [error, setError] = React.useState('');
  const [cardError, setCardError] = React.useState<VgsCardError | null>(null);
  const [customerError, setCustomerError] = React.useState('');
  const [payPlanError, setPayPlanError] = React.useState('');
  const [emailVerificationRequired, setEmailVerificationRequired] = React.useState<boolean>(true);
  const [phoneVerificationRequired, setPhoneVerificationRequired] = React.useState(true);
  const [email, setEmail] = React.useState(params.email);
  const [phoneNo, setPhoneNo] = React.useState(params.phoneNo);
  const [isFirstPaymentEdit, setIsFirstPaymentEdit] = React.useState<boolean>(false);
  const [kycCustomToken, setKycCustomToken] = React.useState<string>('');

  const offerDetails = payPlanOffer?.offerDetails || null;
  const payPlanParameters = offerDetails?.payPlanParameters || null;
  const firstInstalmentAmount = payPlanParameters?.initialPayment || minFirstInstalmentAmount;

  const onError = React.useCallback(
    (error: unknown) => {
      const message = getErrorMessage(error);
      sendMessage(toErrorMessage(message, params.elementId));
      return message;
    },
    [sendMessage, params.elementId],
  );

  const onCardError = React.useCallback(
    (error: VgsCardError) => {
      onError(error?.formError);
      setCardError(error);
      return error;
    },
    [onError],
  );

  const onCustomerError = React.useCallback(
    (error: string) => {
      onError(error);
      setCustomerError(error);
      return error;
    },
    [onError],
  );

  const handlePaymentTypeChanged = React.useCallback(
    (paymentType: PaymentType) => {
      sendMessage(toEventMessage(EventName.ToggledPaymentType, params.elementId, { paymentType }));
      update({ paymentType });
    },
    [sendMessage, update, params.elementId],
  );

  const handleCardSubmit = React.useCallback(async () => {
    setCardError(null);
    setPending(true);
    try {
      const { cardError, cardPaymentSource } = await onCardSubmit();
      cardPaymentSource && sendMessage(toCardPaymentSourceMessage(cardPaymentSource, params.elementId));
      cardError && onCardError(cardError);
    } catch (error) {
      const formError = getErrorMessage(error);
      onCardError({ formError });
    } finally {
      setPending(false);
    }
  }, [params, onCardSubmit, sendMessage, onCardError]);

  const handlePayCardSubmit = React.useCallback(async () => {
    setCardError(null);
    setPending(true);
    try {
      const { cardError, paymentTokenId } = await onPayCardSubmit(params.paycardAmount, params.currency);

      paymentTokenId &&
        sendMessage(
          toPaymentTokenMessage(
            paymentTokenId,
            {
              amount: params.paycardAmount,
              currency: params.currency,
              paymentType: 'paycard',
            },
            params.elementId,
          ),
        );
      cardError && onCardError(cardError);
    } catch (error) {
      const formError = getErrorMessage(error);
      onCardError({ formError });
    } finally {
      setPending(false);
    }
  }, [params, onPayCardSubmit, sendMessage, onCardError]);

  const handlePayPlanSubmit = React.useCallback(async () => {
    setPayPlanError('');
    setPending(true);

    try {
      if (!offerDetails) throw Error("Sorry, we weren't able to determine your eligibility");
      if (!isTermsAgreed) throw Error('Plan creation incomplete or terms not accepted');

      const { paymentTokenId } = await onCreatePayPlanSubmit();
      paymentTokenId &&
        sendMessage(
          toPaymentTokenMessage(
            paymentTokenId,
            {
              amount: params.payplanAmount,
              currency: params.currency,
              paymentType: 'payplan',
            },
            params.elementId,
          ),
        );
    } catch (error) {
      setPayPlanError(onError(error));
    } finally {
      setPending(false);
    }
  }, [
    offerDetails,
    isTermsAgreed,
    onCreatePayPlanSubmit,
    sendMessage,
    params.payplanAmount,
    params.currency,
    params.elementId,
    onError,
  ]);

  const handleIsTermsAgreed = React.useCallback(
    (isTermsAgreed: boolean) => {
      isTermsAgreed && sendMessage(toEventMessage(EventName.PaymentPlanTermsAccepted, params.elementId));
      update({ isTermsAgreed });
    },
    [sendMessage, update, params.elementId],
  );

  const onKycEligibilitySuccess = React.useCallback(async () => {
    await onCreatePayPlanData();
    update({ checkoutStep: CheckoutStep.PlanReview });
  }, [onCreatePayPlanData, update]);

  const checkKycEligibility = React.useCallback(() => {
    update({ checkoutStep: CheckoutStep.IdentityVerification });
  }, [update]);

  const handleReviewPaymentPlanSubmit = React.useCallback<PaymentOptionProps['onReviewPaymentPlanSubmit']>(
    async ({ email = '', phoneNo = '' }) => {
      email && setEmail(email);
      phoneNo && setPhoneNo(phoneNo);

      setCardError(null);
      setCustomerError('');
      setPending(true);
      try {
        const { customerId } = await onGetCustomerId(email, phoneNo);

        // new card
        if (!cardPaymentSourceId) {
          try {
            const { cardError } = await onPayPlanCardSubmit();
            if (cardError) return onCardError(cardError);
          } catch (error) {
            const formError = getErrorMessage(error);
            return onCardError({ formError });
          }
        }

        // saved card
        if (cardPaymentSourceId) {
          if (!vaultCvc) throw Error(CARD_ERROR_MESSAGE);

          const { isValid, errors } = vaultCvc.validate();

          if (!isValid) return onCardError(toCardError(errors));
        }

        if (!currentUser?.isVerified) {
          await sendEmailAndPhoneVerificationCodes(email, phoneNo, customerId);
          update({ checkoutStep: CheckoutStep.UserVerification });
        } else {
          checkKycEligibility();
        }
      } catch (error) {
        const customerError = getErrorMessage(error);
        onCustomerError(customerError);
      } finally {
        setPending(false);
      }
    },
    [
      onGetCustomerId,
      cardPaymentSourceId,
      currentUser,
      onPayPlanCardSubmit,
      onCardError,
      sendEmailAndPhoneVerificationCodes,
      update,
      checkKycEligibility,
      onCustomerError,
      vaultCvc,
    ],
  );

  const handleVerificationCodesSubmit = React.useCallback(
    async (
      customerId: string,
      emailVerification: SendCustomerVerification['emailVerification'],
      phoneVerification: SendCustomerVerification['phoneVerification'],
      emailVerificationCode: string,
      phoneVerificationCode: string,
    ) => {
      setPending(true);
      try {
        const { customerError, customToken } = await onVerificationCodesSubmit(
          customerId,
          emailVerification,
          phoneVerification,
          emailVerificationCode,
          phoneVerificationCode,
        );

        if (customerError) throw Error(customerError);

        setKycCustomToken(customToken ?? '');
        return { customToken };
      } catch (error) {
        const customerError = onError(error);
        return { customerError };
      } finally {
        setPending(false);
      }
    },
    [onVerificationCodesSubmit, onError],
  );

  const handleSubmitMessage = React.useCallback(
    () =>
      pending
        ? sendMessage(toErrorMessage('Please wait, payment form is processing your request.', params.elementId))
        : params.showCardOnly
        ? handleCardSubmit()
        : paymentType === 'paycard'
        ? handlePayCardSubmit()
        : paymentType === 'payplan'
        ? handlePayPlanSubmit()
        : null,
    [pending, paymentType, handleCardSubmit, handlePayCardSubmit, handlePayPlanSubmit, sendMessage, params],
  );

  const onMessage = React.useCallback(
    (message: unknown) => {
      message === EventName.Submit && handleSubmitMessage();
    },
    [handleSubmitMessage],
  );

  React.useEffect(() => {
    if (checkoutRef) {
      checkoutRef.current = {
        onMessage,
      };
    }
  }, [checkoutRef, onMessage]);

  React.useEffect(() => {
    (async () => {
      setError('');
      setPending(true);
      try {
        await onMount();
      } catch (error) {
        setError(onError(error));
      } finally {
        setPending(false);
      }
    })();
  }, [onMount, onError]);

  React.useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email || '');
      setPhoneNo(currentUser.phoneNumber || '');
    }
  }, [currentUser]);

  const handleMountVaultCard = React.useCallback<NonNullable<PaymentOptionProps['onMountVaultCard']>>(
    async (onEvent) => {
      setCardError(null);
      try {
        return await onMountVaultCard?.(onEvent);
      } catch (error) {
        const formError = getErrorMessage(error);
        onCardError({ formError });
      }
    },
    [onMountVaultCard, onCardError],
  );

  const onResize = React.useCallback<NonNullable<LayoutProps['onResize']>>(
    (width, height) => sendMessage(toResizeMessage(height || 0, params.elementId)),
    [sendMessage, params.elementId],
  );

  React.useEffect(() => {
    /**
     * kycCustomToken is got from verification codes for new customer
     * initParams.customerToken is from param for existing customer
     */
    const token = currentUser ? initParams?.customerToken || '' : kycCustomToken;
    if (checkoutStep !== CheckoutStep.IdentityVerification || !params.publicKey || !token) {
      return;
    }

    window.LimepayIdentity.render(
      {
        sessionId: sessionCorrelationId,
        elementId: 'identityCont',
        publicKey: params.publicKey,
        customToken: token,
        firstName: params.customerFirstName ?? '',
        middleName: params.customerMiddleName ?? '',
        lastName: params.customerLastName ?? '',
        address: params.customerResidentialAddress ?? '',
      },
      () => {
        onError('Kyc denied or attempts exhausted');
      },
      () => {
        const onSuccess = async () => {
          try {
            setPending(true);
            await onKycEligibilitySuccess();
          } catch (e) {
            const errorMsg = getErrorMessage(e);
            setPayPlanError(errorMsg);
            onError(errorMsg);
            update({ checkoutStep: CheckoutStep.PlanReview });
          } finally {
            setPending(false);
          }
        };
        onSuccess();
      },
    );
  }, [
    checkoutStep,
    params,
    kycCustomToken,
    onError,
    onKycEligibilitySuccess,
    initParams?.customerToken,
    currentUser,
    update,
  ]);

  if (error) return <Layout error={error} pending />;
  if (!config) return <Layout pending />;

  const planReview = isFirstPaymentEdit ? (
    <InitialPayment
      params={params}
      firstInstalmentAmount={firstInstalmentAmount}
      minFirstInstalmentAmount={minFirstInstalmentAmount}
      isFirstPaymentEdit={isFirstPaymentEdit}
      setIsFirstPaymentEdit={setIsFirstPaymentEdit}
    />
  ) : (
    <PlanReview
      params={params}
      config={config}
      isCounterOffer={!!offerDetails?.isCounterOffer}
      payPlanParameters={payPlanParameters}
      payPlanError={payPlanError}
      firstInstalmentAmount={firstInstalmentAmount}
      minFirstInstalmentAmount={minFirstInstalmentAmount}
      isFirstPaymentEdit={isFirstPaymentEdit}
      setIsFirstPaymentEdit={setIsFirstPaymentEdit}
      isTermsAgreed={isTermsAgreed}
      setIsTermsAgreed={handleIsTermsAgreed}
      onPayPlanSubmit={handlePayPlanSubmit}
      onBack={() => {
        setPayPlanError('');
        update({ checkoutStep: CheckoutStep.PaymentOption });
      }}
    />
  );

  return (
    <Layout
      paymentType={paymentType}
      pending={pending}
      checkoutCSSOverride={params.checkoutCSSOverride}
      onResize={onResize}
    >
      <div style={{ display: checkoutStep === CheckoutStep.PaymentOption ? 'block' : 'none' }}>
        <PaymentOption
          params={params}
          config={config}
          paymentType={paymentType}
          onCardValid={onCardValid}
          cardError={cardError}
          customerError={customerError}
          email={email}
          phoneNo={phoneNo}
          setEmail={setEmail}
          setPhoneNo={setPhoneNo}
          emailVerificationRequired={emailVerificationRequired}
          setEmailVerificationRequired={setEmailVerificationRequired}
          phoneVerificationRequired={phoneVerificationRequired}
          setPhoneVerificationRequired={setPhoneVerificationRequired}
          currentUser={currentUser}
          cardPaymentSources={cardPaymentSources}
          cardPaymentSourceId={cardPaymentSourceId}
          setCardPaymentSourceId={(cardPaymentSourceId) => update({ cardPaymentSourceId })}
          setEmailVerificationCode={(emailVerificationCode) => update({ emailVerificationCode })}
          setPhoneVerificationCode={(phoneVerificationCode) => update({ phoneVerificationCode })}
          setPending={setPending}
          onMountVaultCard={handleMountVaultCard}
          onPaymentTypeChanged={handlePaymentTypeChanged}
          onCardSubmit={handleCardSubmit}
          onPayCardSubmit={handlePayCardSubmit}
          onReviewPaymentPlanSubmit={handleReviewPaymentPlanSubmit}
          onValidationError={onError}
          setB2BCardUsageScope={(b2bCardUsageScope) => update({ b2bCardUsageScope })}
        />
      </div>
      {checkoutStep === CheckoutStep.UserVerification && (
        <UserVerification
          customerId={customerId}
          email={email}
          phoneNo={phoneNo}
          emailVerification={emailVerification}
          phoneVerification={phoneVerification}
          emailVerificationCode={emailVerificationCode}
          phoneVerificationCode={phoneVerificationCode}
          setEmailVerificationCode={(emailVerificationCode) => update({ emailVerificationCode })}
          setPhoneVerificationCode={(phoneVerificationCode) => update({ phoneVerificationCode })}
          onVerificationCodesSubmit={handleVerificationCodesSubmit}
          checkoutStep={checkoutStep}
          setCheckoutStep={(checkoutStep) => update({ checkoutStep })}
          setPending={setPending}
          onSuccess={() => checkKycEligibility()}
        />
      )}
      {checkoutStep === CheckoutStep.IdentityVerification && (
        <KycWrapper>
          <div className="three-p">
            <div id="identityCont"></div>
            <GoBackBtn id="lpIdentityBackButton" onClick={() => update({ checkoutStep: CheckoutStep.PaymentOption })}>
              Go back
            </GoBackBtn>
          </div>
        </KycWrapper>
      )}
      {checkoutStep === CheckoutStep.PlanReview && planReview}
    </Layout>
  );
};
