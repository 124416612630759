import 'firebase/auth';

import {
  SendCustomerVerification,
  createOrGetCustomer,
  defaultHeaders,
  getCheckoutConfig,
  getCustomer,
  queryCardPaymentSources,
  sendCustomerVerification,
  verifyCustomer,
} from 'app';
import {
  CreatePayPlanOfferResponse,
  ManualCardToken,
  SavedPaymentSourceToken,
  VAULT_CARD,
  createPayPlanOffer,
  toSavedCardPaymentSource,
  vaultCreatePayPlan,
  vaultCreatePaymentSource,
  vaultCreatePaymentToken,
} from 'app/api.april';
import { PaymentOptionProps } from 'components';
import firebase from 'firebase/app';
import { useCallback, useContext } from 'react';

import { VaultCardType, createVault } from '@april/lib-ui';

import {
  AnyMessage,
  CARD_ERROR_MESSAGE,
  CUSTOMER_TOKEN_ERROR_MESSAGE,
  Currency,
  DispatchContext,
  GET_PAYPLAN_DATA_ERROR_MESSAGE,
  STATE_ERROR_MESSAGE,
  State,
  StateContext,
  VgsCardError,
  cardCvcId,
  cardCvcOnlyId,
  cardExpiryId,
  cardNumberId,
  checkPayPlanAmountEligibility,
  fieldStyles,
  getVaultHost,
  onVaultError,
  submitCardinalDdcForm,
  toCardError,
  toCardPaymentSourceBrand,
  updateAction,
} from './';
import { loadKycScript } from './loadKycScript';
import { logRocketIdentify, logRocketInit, logRocketTrack } from './logrocket';
import { CardPaymentSource, UserClaims } from './types';
import { getPayPlanVariantData, isB2B } from './utils';

export const useCheckoutContext = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  if (!state || !dispatch) throw Error('Checkout context is required');

  const update = useCallback((state: Partial<State>) => dispatch(updateAction(state)), [dispatch]);

  return { state, update };
};

export const useMount = () => {
  const { state, update } = useCheckoutContext();
  const { params, initParams } = state;

  const onMount = useCallback(async () => {
    const config = await getCheckoutConfig(params.apiBaseUri || '', params.publicKey);
    config.isB2B = isB2B(params, config);
    await loadKycScript(config.apiBaseUri);

    if (params.paymentType === 'payplan') {
      const { payPlanAmountEligibility } = checkPayPlanAmountEligibility(params, config);
      !payPlanAmountEligibility && update({ paymentType: 'paycard' });
    }

    const initializeApp = async (options: Object) => {
      try {
        await firebase.app().delete();
      } catch (error) {}
      return firebase.initializeApp(options);
    };

    const [firebaseApp] = await Promise.all([
      initializeApp({
        apiKey: config.authApiKey,
        authDomain: config.authDomain,
      }),
    ]);

    const firebaseAuth = firebaseApp.auth();
    firebaseAuth.tenantId = config.tenantId;
    if (initParams?.customerToken) {
      const userCredential = await firebaseAuth.signInWithCustomToken(initParams?.customerToken);
      const idTokenResult = await userCredential.user?.getIdTokenResult();

      if (!idTokenResult) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      const claims = idTokenResult.claims as unknown as UserClaims;
      const customerId = claims.limepay.customerId;
      const currentUser = await getCustomer(config.apiBaseUri, customerId, idTokenResult.token);

      update({
        currentUser,
        customerId,
      });

      if (!params.hideSavedCards && config.displayCustomerSavedPaymentMethodsInCheckout) {
        const cardPaymentSources = await queryCardPaymentSources(config.apiBaseUri, customerId, idTokenResult.token);
        update({
          cardPaymentSources,
        });
      }
    }

    const { initialPayment } = getPayPlanVariantData(params, config);
    update({
      config,
      firebaseAuth,
      minFirstInstalmentAmount: initialPayment,
    });

    logRocketInit(async (sessionUrl) => {
      defaultHeaders['Logrocket-Session-Url'] = sessionUrl;
      logRocketIdentify(firebaseAuth);
      const config = await getCheckoutConfig(params.apiBaseUri || '', params.publicKey); // track config request
      logRocketTrack({
        merchantId: config.merchantId,
        marketplaceId: config.marketplaceId,
        sessionCorrelationId: defaultHeaders['Session-Correlation-Id'],
      });
    });
  }, [params, initParams, update]);

  return onMount;
};

export const useSendMessage = () => {
  const sendMessage = useCallback(
    (message: AnyMessage): void => window.parent.postMessage(JSON.stringify(message), '*'),
    [],
  );
  return sendMessage;
};

export const useMountVaultCard = () => {
  const { update } = useCheckoutContext();

  const onMountVaultCard = useCallback<NonNullable<PaymentOptionProps['onMountVaultCard']>>(
    async (onEvent) => {
      const vaultHost = await getVaultHost();

      const [vaultCard, vaultCvc] = await Promise.all([
        createVault({
          vaultHost,
          fields: {
            cardNumber: { elementId: cardNumberId, fieldStyles, inputProps: { placeholder: '1234 1234 1234 1234' } },
            expiryDate: { elementId: cardExpiryId, fieldStyles },
            cardCvc: { elementId: cardCvcId, fieldStyles },
          },
          onEvent,
          onError: onVaultError,
        }),
        createVault({
          vaultHost,
          fields: {
            cardCvc: { elementId: cardCvcOnlyId, fieldStyles },
          },
          onEvent,
          onError: onVaultError,
        }),
      ]);

      update({ vaultCard, vaultCvc });
    },
    [update],
  );

  return onMountVaultCard;
};

export const useCardTypeErrorCheck = () => {
  const { state } = useCheckoutContext();
  const { config } = state;

  const onCardTypeErrorCheck = useCallback(
    (cardType?: VaultCardType | null): string | null => {
      if (!cardType || !config?.allowedCardBrands) return null;

      const cardBrand = toCardPaymentSourceBrand(cardType);

      if (cardBrand && !config.allowedCardBrands.includes(cardBrand)) return `${cardBrand} not supported`;

      return null;
    },
    [config],
  );

  return onCardTypeErrorCheck;
};

export const useCardSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    params,
    config,
    firebaseAuth,
    currentUser,
    b2bCardUsageScope,
    cardPaymentSourceId,
    cardPaymentSources,
    vaultCard,
    vaultCvc,
  } = state;

  const onCardSubmit = useCallback(async (): Promise<{
    cardError?: VgsCardError;
    cardPaymentSource?: CardPaymentSource;
  }> => {
    if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

    const useSavedCard = cardPaymentSources.length > 0 && !!cardPaymentSourceId;

    let cardPaymentSource: CardPaymentSource | undefined;
    if (useSavedCard) {
      if (!vaultCvc) throw Error(CARD_ERROR_MESSAGE);

      const { isValid, errors } = vaultCvc.validate();

      if (!isValid) return { cardError: toCardError(errors) };

      cardPaymentSource = cardPaymentSources.find(
        (cardPaymentSource) => cardPaymentSource.cardPaymentSourceId === cardPaymentSourceId,
      );
    } else {
      if (!vaultCard) throw Error(CARD_ERROR_MESSAGE);

      // save card
      if (currentUser) {
        const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        const { cardError, response } = await vaultCreatePaymentSource(vaultCard, customerIdToken, {
          CreateSavedPaymentSource: {
            sourceMethod: {
              CardMethod: VAULT_CARD,
            },
            usageScope: config.isB2B && b2bCardUsageScope ? b2bCardUsageScope : undefined,
          },
        });

        if (cardError) return { cardError };
        if (!response) throw Error(CARD_ERROR_MESSAGE);

        cardPaymentSource = toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp);
      } else {
        // anonymous
        const { cardError, response } = await vaultCreatePaymentSource(vaultCard, params.publicKey, {
          CreateTemporaryCardSource: {
            ...VAULT_CARD,
          },
        });

        if (cardError) return { cardError };
        if (!response) throw Error(CARD_ERROR_MESSAGE);

        cardPaymentSource = toSavedCardPaymentSource(response.paymentSourceId, response.sourceMethod.CardMethodResp);
      }
    }

    return { cardPaymentSource };
  }, [
    config,
    cardPaymentSources,
    cardPaymentSourceId,
    firebaseAuth,
    currentUser,
    params.publicKey,
    b2bCardUsageScope,
    vaultCard,
    vaultCvc,
  ]);

  return onCardSubmit;
};

export const useCardinalDdcSubmit = () => {
  const { state } = useCheckoutContext();
  const { config } = state;

  const onCardinalDdcSubmit = useCallback(
    async (bin?: string | null) => {
      if (!config?.cardinalDdcJwt || !bin) return null;

      return submitCardinalDdcForm(bin, config.cardinalDdcJwt);
    },
    [config],
  );

  return onCardinalDdcSubmit;
};

export const usePayCardSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    params,
    config,
    currentUser,
    firebaseAuth,
    b2bCardUsageScope,
    cardPaymentSourceId,
    cardPaymentSources,
    vaultCard,
    vaultCvc,
  } = state;

  const onCardinalDdcSubmit = useCardinalDdcSubmit();
  const onCardTypeErrorCheck = useCardTypeErrorCheck();

  const onPayCardSubmit = useCallback(
    async (amount: number, currency: Currency): Promise<{ cardError?: VgsCardError; paymentTokenId?: string }> => {
      if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

      const showSavedCards = cardPaymentSources.length > 0 && !!cardPaymentSourceId;

      if (showSavedCards && firebaseAuth) {
        if (!vaultCvc) throw Error(CARD_ERROR_MESSAGE);

        const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
        if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

        const cardPaymentSource = cardPaymentSources.find((card) => card.cardPaymentSourceId === cardPaymentSourceId);
        const threeDsSessionId = await onCardinalDdcSubmit(cardPaymentSource?.cardBin);

        const { cardError, response } = await vaultCreatePaymentToken<SavedPaymentSourceToken>(
          vaultCvc,
          customerIdToken,
          {
            CreateSavedPaymentSourceToken: {
              amount: {
                minorCurrencyUnits: amount,
                currency,
              },
              paymentSourceId: cardPaymentSourceId,
              cvc: VAULT_CARD.cvc,
              threeDsSessionId,
            },
          },
        );

        if (cardError) return { cardError };
        if (!response) throw Error(CARD_ERROR_MESSAGE);

        return { paymentTokenId: response.SavedPaymentSourceToken.paymentTokenId };
      } else {
        if (!vaultCard) throw Error(CARD_ERROR_MESSAGE);

        const cardTypeError = onCardTypeErrorCheck(vaultCard.fields.cardNumber?.state.cardType);
        if (cardTypeError) throw Error(cardTypeError);

        const threeDsSessionId = await onCardinalDdcSubmit(vaultCard?.fields.cardNumber?.state.bin);

        const { cardError, response } = await vaultCreatePaymentToken<ManualCardToken>(vaultCard, params.publicKey, {
          CreateManualCardToken: {
            amount: {
              minorCurrencyUnits: amount,
              currency,
            },
            card: VAULT_CARD,
            threeDsSessionId,
          },
        });

        if (cardError) return { cardError };
        if (!response) throw Error(CARD_ERROR_MESSAGE);

        // save card
        if (currentUser) {
          const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
          if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

          await vaultCreatePaymentSource(vaultCard, customerIdToken, {
            CreateSavedPaymentSource: {
              sourceMethod: {
                CardMethod: VAULT_CARD,
              },
              usageScope: config.isB2B && b2bCardUsageScope ? b2bCardUsageScope : undefined,
            },
          });
        }

        return { paymentTokenId: response.ManualCardToken.paymentTokenId };
      }
    },
    [
      config,
      cardPaymentSources,
      cardPaymentSourceId,
      currentUser,
      firebaseAuth,
      params.publicKey,
      b2bCardUsageScope,
      vaultCard,
      vaultCvc,
      onCardinalDdcSubmit,
      onCardTypeErrorCheck,
    ],
  );

  return onPayCardSubmit;
};

export const usePayPlanCardSubmit = () => {
  const { state } = useCheckoutContext();
  const { params, vaultCard } = state;

  const onCardTypeErrorCheck = useCardTypeErrorCheck();

  const onPayPlanCardSubmit = useCallback(async (): Promise<{
    cardError?: VgsCardError;
  }> => {
    if (!vaultCard) throw Error(CARD_ERROR_MESSAGE);

    const cardTypeError = onCardTypeErrorCheck(vaultCard.fields.cardNumber?.state.cardType);
    if (cardTypeError) throw Error(cardTypeError);

    const { cardError, response } = await vaultCreatePaymentSource(vaultCard, params.publicKey, {
      CreateTemporaryCardSource: {
        ...VAULT_CARD,
      },
    });

    if (cardError) return { cardError };
    if (!response) throw Error(CARD_ERROR_MESSAGE);

    return {};
  }, [params, vaultCard, onCardTypeErrorCheck]);

  return onPayPlanCardSubmit;
};

export const useGetCustomerId = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, currentUser } = state;

  const onGetCustomerId = useCallback(
    async (email: string, phoneNo: string) => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      let customerId = currentUser?.customerId || '';

      if (!currentUser) {
        customerId = await createOrGetCustomer(config.apiBaseUri, params.publicKey, {
          emailAddress: email,
          phoneNumber: phoneNo,
        });
        update({ customerId });
      }

      return { customerId };
    },
    [params, config, currentUser, update],
  );

  return onGetCustomerId;
};

export const useSendEmailAndPhoneVerificationCodes = () => {
  const { state, update } = useCheckoutContext();
  const { params, config } = state;

  const sendEmailAndPhoneVerificationCodes = useCallback(
    async (email: string, phoneNo: string, customerId: string) => {
      if (!config) throw Error(STATE_ERROR_MESSAGE);

      const { emailVerification, phoneVerification } = await sendCustomerVerification(
        config.apiBaseUri,
        params.publicKey,
        customerId,
        {
          emailAddress: email,
          phoneNumber: phoneNo,
          mode: 'Phone',
        },
      );

      if (!phoneVerification) throw Error('Error sending verification codes');

      update({ emailVerification, phoneVerification });
    },
    [params, config, update],
  );

  return sendEmailAndPhoneVerificationCodes;
};

export const useVerificationCodesSubmit = () => {
  const { state } = useCheckoutContext();
  const { params, config, firebaseAuth } = state;

  const onVerificationCodesSubmit = useCallback(
    async (
      customerId: string,
      emailVerification: SendCustomerVerification['emailVerification'],
      phoneVerification: SendCustomerVerification['phoneVerification'],
      emailVerificationCode: string,
      phoneVerificationCode: string,
    ): Promise<{ customerError?: string; customToken?: string }> => {
      if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

      const { error: verifyCustomerError, customToken } = await verifyCustomer(config.apiBaseUri, params.publicKey, {
        customerId,
        emailVerification,
        phoneVerification,
        emailVerificationCode: emailVerification ? emailVerificationCode : undefined,
        phoneVerificationCode,
      });

      if (!customToken) return { customerError: verifyCustomerError };

      const userCredential = await firebaseAuth.signInWithCustomToken(customToken);
      if (!userCredential) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      logRocketIdentify(firebaseAuth);

      return { customToken };
    },
    [params, config, firebaseAuth],
  );

  return onVerificationCodesSubmit;
};

export type onInitialPaymentChange = (payload: {
  amount?: number;
}) => Promise<{ customerError?: string; payPlanOffer?: CreatePayPlanOfferResponse }>;
export const useInitialPaymentChange = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, firebaseAuth, minFirstInstalmentAmount } = state;

  const onInitialPaymentChange = useCallback<onInitialPaymentChange>(
    async ({ amount }) => {
      if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

      const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
      if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

      try {
        const payPlanOffer = await createPayPlanOffer(customerIdToken, {
          amount: {
            minorCurrencyUnits: params.payplanAmount,
            currency: params.currency,
          },
          initialPayment: amount || minFirstInstalmentAmount,
          payPlanVariant: config.payPlanVariant,
        });

        if (payPlanOffer.offerDetails.isCounterOffer) throw Error(GET_PAYPLAN_DATA_ERROR_MESSAGE);

        update({
          payPlanOffer,
        });
        return { payPlanOffer };
      } catch (e) {
        return {
          customerError: GET_PAYPLAN_DATA_ERROR_MESSAGE,
        };
      }
    },
    [params, config, firebaseAuth, minFirstInstalmentAmount, update],
  );

  return onInitialPaymentChange;
};

export const useCreatePayPlanData = () => {
  const { state, update } = useCheckoutContext();
  const { params, config, firebaseAuth, minFirstInstalmentAmount } = state;

  const onCreatePayPlanData = useCallback(async (): Promise<{
    payPlanOffer: CreatePayPlanOfferResponse;
  }> => {
    if (!config || !firebaseAuth) throw Error(STATE_ERROR_MESSAGE);

    const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
    if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

    const payPlanOffer = await createPayPlanOffer(customerIdToken, {
      amount: {
        minorCurrencyUnits: params.payplanAmount,
        currency: params.currency,
      },
      initialPayment: minFirstInstalmentAmount,
      payPlanVariant: config.payPlanVariant,
    });

    const payPlanParameters = payPlanOffer.offerDetails.payPlanParameters;

    update({
      payPlanOffer,
      minFirstInstalmentAmount: payPlanParameters?.initialPayment || minFirstInstalmentAmount,
    });
    return { payPlanOffer };
  }, [params, config, firebaseAuth, minFirstInstalmentAmount, update]);

  return onCreatePayPlanData;
};

export const useCreatePayPlanSubmit = () => {
  const { state } = useCheckoutContext();
  const {
    config,
    cardPaymentSources,
    cardPaymentSourceId,
    firebaseAuth,
    payPlanOffer,
    b2bCardUsageScope,
    vaultCard,
    vaultCvc,
  } = state;

  const onCardinalDdcSubmit = useCardinalDdcSubmit();

  const onCreatePayPlanSubmit = useCallback(async () => {
    if (!config || !firebaseAuth || !payPlanOffer) throw Error(STATE_ERROR_MESSAGE);

    const customerIdToken = await firebaseAuth.currentUser?.getIdToken();
    if (!customerIdToken) throw Error(CUSTOMER_TOKEN_ERROR_MESSAGE);

    let _cardPaymentSourceId = cardPaymentSourceId;
    let cardBin = '';

    // saved card?
    if (!_cardPaymentSourceId) {
      if (!vaultCard) throw Error(CARD_ERROR_MESSAGE);

      const { cardError, response } = await vaultCreatePaymentSource(vaultCard, customerIdToken, {
        CreateSavedPaymentSource: {
          sourceMethod: {
            CardMethod: VAULT_CARD,
          },
          usageScope: config.isB2B && b2bCardUsageScope ? b2bCardUsageScope : undefined,
        },
      });

      if (cardError || !response) throw Error(cardError?.formError ?? CARD_ERROR_MESSAGE);

      _cardPaymentSourceId = response.paymentSourceId;
      cardBin = response.sourceMethod.CardMethodResp.cardBin;
    } else {
      const cardPaymentSource = cardPaymentSources.find((card) => card.cardPaymentSourceId === _cardPaymentSourceId);
      cardBin = cardPaymentSource?.cardBin ?? '';
    }

    const threeDsSessionId = await onCardinalDdcSubmit(cardBin);

    const vault = cardPaymentSourceId ? vaultCvc : vaultCard;
    if (!vault) throw Error(CARD_ERROR_MESSAGE);

    const { cardError, response } = await vaultCreatePayPlan(vault, customerIdToken, {
      termsAndConditionsAccepted: true,
      offerDetails: payPlanOffer.offerDetails,
      offerVerificationToken: payPlanOffer.offerVerificationToken,
      paymentSource: {
        Card: {
          paymentSourceId: _cardPaymentSourceId,
          cvc: VAULT_CARD.cvc,
        },
      },
      threeDsSessionId,
    });

    if (cardError || !response) throw Error(cardError?.formError ?? CARD_ERROR_MESSAGE);

    return {
      paymentTokenId: response.paymentTokenId,
    };
  }, [
    config,
    firebaseAuth,
    payPlanOffer,
    cardPaymentSources,
    cardPaymentSourceId,
    b2bCardUsageScope,
    onCardinalDdcSubmit,
    vaultCard,
    vaultCvc,
  ]);

  return onCreatePayPlanSubmit;
};
