import { defaultHeaders } from 'app/api';
import { handleVaultSubmitError, toCardError } from 'lib/vault';
import { VgsCardError } from 'lib/vgs';

import { Vault, handleVaultSubmitResponse } from '@april/lib-ui';

import { Amount, Card, CardBrand, CardFunding, ErrorResponse } from './types';

export type TokenCardSource = {
  expiryDate: {
    expiryMonth: string;
    expiryYear: string;
  };
  last4: string;
  brand: CardBrand;
  bin: string;
  funding: CardFunding;
  country?: string;
};

export type CreateManualCardToken = {
  CreateManualCardToken: {
    amount: Amount;
    card: Card;
    threeDsSessionId: string | null;
  };
};

export type CreateSavedPaymentSourceToken = {
  CreateSavedPaymentSourceToken: {
    amount: Amount;
    paymentSourceId: string;
    cvc?: Card['cvc'];
    threeDsSessionId: string | null;
  };
};

export type AnyCreatePaymentTokenRequest = CreateManualCardToken | CreateSavedPaymentSourceToken;

export type ManualCardToken = {
  ManualCardToken: {
    paymentTokenId: string;
    amount: Amount;
  } & TokenCardSource;
};

export type SavedPaymentSourceToken = {
  SavedPaymentSourceToken: {
    paymentTokenId: string;
    amount: Amount;
    paymentSourceId: string;
    savedPaymentSource: {
      TokenSavedPaymentSourceCard: TokenCardSource;
    };
  };
};

export type AnyCreatePaymentTokenResponse = ManualCardToken | SavedPaymentSourceToken;

export const vaultCreatePaymentToken = async <T = AnyCreatePaymentTokenResponse>(
  vault: Vault,
  token: string,
  payload: AnyCreatePaymentTokenRequest,
): Promise<{
  response?: T;
  cardError?: VgsCardError;
}> => {
  const { isValid, errors } = vault.validate();

  if (!isValid) return { cardError: toCardError(errors) };

  const response = await vault
    .submit({
      path: '/tokens',
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleVaultSubmitError)
    .then((res) => handleVaultSubmitResponse<T, ErrorResponse>(res, ({ message, detail }) => detail || message));

  return { response };
};
