import { PayPlanParameters } from 'app/api.april';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Config, Params, currencyFormat, toCurrency, toCurrencySymbol } from 'lib';
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

dayjs.extend(customParseFormat);

export interface PlanReviewProps {
  params: Params;
  config: Config;
  isCounterOffer: boolean;
  payPlanParameters: PayPlanParameters | null;
  payPlanError: string;
  firstInstalmentAmount: number;
  minFirstInstalmentAmount: number;
  isFirstPaymentEdit: boolean;
  isTermsAgreed: boolean;
  setIsTermsAgreed(agreed: boolean): void;
  setIsFirstPaymentEdit(fn: (value: boolean) => boolean): void;
  onPayPlanSubmit(): void;
  onBack(): void;
}

export const PlanReview: React.FC<PlanReviewProps> = ({
  params,
  config,
  isCounterOffer,
  payPlanParameters,
  payPlanError,
  firstInstalmentAmount,
  minFirstInstalmentAmount,
  isFirstPaymentEdit,
  isTermsAgreed,
  setIsTermsAgreed,
  setIsFirstPaymentEdit,
  onPayPlanSubmit,
  onBack,
}) => {
  const [initialPaymentAmount, setInitialPaymentAmount] = React.useState<string>(
    currencyFormat(firstInstalmentAmount, params.currency),
  );

  const { balanceInstalmentsCount, balanceInstalmentAmount, scheduleStartDate } = React.useMemo(() => {
    const proposedSchedule = payPlanParameters?.proposedSchedule;

    return {
      balanceInstalmentsCount: proposedSchedule ? proposedSchedule.length - 1 : 0,
      balanceInstalmentAmount: currencyFormat(proposedSchedule?.[1].amount || 0, params.currency),
      scheduleStartDate: dayjs(proposedSchedule?.[1].at).format('D MMM YYYY'),
    };
  }, [params, payPlanParameters]);

  const currencySymbol = toCurrencySymbol(params.currency);
  const isPayInFull = isCounterOffer && minFirstInstalmentAmount === params.payplanAmount;
  const isDeferred = config.payPlanVariant === 'payplan_fortnight_0_100';
  const firstInstalmentPercent = React.useMemo(
    () => `${((minFirstInstalmentAmount / params.payplanAmount) * 100).toFixed(0)}%`,
    [params, minFirstInstalmentAmount],
  );
  const planTitle = isCounterOffer
    ? isPayInFull
      ? 'Pay in Full to Proceed'
      : 'Confirm Update to Your Payment Plan'
    : 'Your Payment Plan';

  const handleGoBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onBack();
  };

  const toggleFirstInstalmentEdit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e?.preventDefault();
    setIsFirstPaymentEdit((prevState: boolean) => !prevState);
  };

  React.useEffect(() => {
    setInitialPaymentAmount(currencyFormat(firstInstalmentAmount, params.currency));
  }, [params, firstInstalmentAmount]);

  const handleTermsAgreeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.checked && setIsTermsAgreed(true);
  };

  const reviewPlanSummary = (
    <>
      <div className="row pt-4 pt-sm-3 pb-3">
        <div className="col-sm-4 pr-0 pb-2 pb-sm-0">
          <h3 className="title-h3">
            Due now:{' '}
            <span className="lpFirstInstalmentAmountPH">
              <span>{currencySymbol}</span>
              {initialPaymentAmount}
            </span>
          </h3>
          {!!params.payplanSurchargeAmount && (
            <div className="surcharge lbl active">
              (inc. <span>{toCurrencySymbol(params.currency)}</span>
              {toCurrency(params.payplanSurchargeAmount)} surcharge in total)
            </div>
          )}
        </div>
        {!isPayInFull && !isDeferred && (
          <div className="col-sm-8 pl-3 pl-sm-1">
            <a className="link style1 lpChangeFirstInstalmentAmountButton" href="#" onClick={toggleFirstInstalmentEdit}>
              Pay more now
            </a>
          </div>
        )}
      </div>
      {!isPayInFull && (
        <div className="row pb-2">
          <div className="col-sm-8 pr-3 pr-sm-0 pb-2 pb-sm-0 pt-2 pt-sm-0">
            {isDeferred ? (
              <p>
                Payment of{' '}
                <span className="lpBalanceInstalmentAmountPH">
                  <span>{currencySymbol}</span>
                  {balanceInstalmentAmount}
                </span>{' '}
                due on <span className="lpFirstInstalmentDatePH">{scheduleStartDate}</span>
              </p>
            ) : (
              <p>
                <span className="lpBalanceInstalmentsCountPH">{balanceInstalmentsCount}</span> fortnightly payments of{' '}
                <span className="lpBalanceInstalmentAmountPH">
                  <span>{currencySymbol}</span>
                  {balanceInstalmentAmount}
                </span>
                , starting <span className="lpFirstInstalmentDatePH">{scheduleStartDate}</span>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );

  const handlePayPlanSubmitClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    await onPayPlanSubmit();
  };

  return (
    <div id="lpReviewCont">
      <div className="three-p btop">
        <div className="form-inputs">
          <div className="lpReviewPlanSummaryCont">
            <a className="link style5 lpBackToCardPage" href="#" onClick={handleGoBack}>
              Back
            </a>
            <h1 className="title-h1">{planTitle}</h1>
            <p className="error-msg-noa" id="lpReviewErrors">
              {payPlanError}
            </p>
            {!payPlanError && isCounterOffer && (
              <div
                style={{
                  padding: '16px 24px',
                  margin: '16px 0',
                  backgroundColor: 'rgba(255, 248, 245, 1)',
                  border: '1px solid rgba(255, 110, 68, 0.3)',
                }}
                data-testid="counter-offer-text"
              >
                {!isPayInFull && !isDeferred && (
                  <>
                    <p style={{ marginBottom: 10 }}>
                      Based on our assessment of your financial history, we have devised the payment schedule below.{' '}
                      <span style={{ fontWeight: 700 }}>
                        To proceed with a payment plan, {firstInstalmentPercent} of the order total is required to be
                        paid today.
                      </span>{' '}
                      The remaining balance will be split evenly over three fortnightly payments.
                    </p>
                    <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                      Successfully paying off this plan will improve your chances of being approved for future payment
                      plans.
                    </p>
                  </>
                )}
                {!isPayInFull && isDeferred && (
                  <>
                    <p style={{ marginBottom: 10 }}>
                      Based on our assessment of your financial history, we're unable to provide you with a traditional
                      payment plan today.{' '}
                      <span style={{ fontWeight: 700 }}>
                        To proceed with this order, {firstInstalmentPercent} of the order total is required to be paid
                        today.
                      </span>
                    </p>
                    <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                      Successfully paying off this plan will improve your chances of being approved for future payment
                      plans.
                    </p>
                  </>
                )}
                {isPayInFull && (
                  <>
                    <p style={{ marginBottom: 10 }}>
                      Based on our assessment of your financial history, we're unable to provide you with a payment plan
                      today.{' '}
                      <span style={{ fontWeight: 700 }}>The total amount for your order is required to be paid.</span>
                    </p>
                    <p style={{ fontSize: 11, color: '#6D6D6D' }}>
                      A successful payment today will improve your chances of being approved for a payment plan in the
                      future.
                    </p>
                  </>
                )}
              </div>
            )}
            {reviewPlanSummary}
          </div>
          <div className="agm">
            <label className="lp-option">
              <span>
                I’ve read and agree to the{' '}
                <a
                  className="link style6 d-inline-block d-sm-inline"
                  href={config.tcUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  {isPayInFull ? 'Payment Terms' : 'Payment Plan Terms'}
                </a>{' '}
                and consent to the collection, use and disclosure of my personal information in accordance with the
                April{' '}
                <a
                  className="link style6 d-inline-block d-sm-inline"
                  href={config.privacyUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </span>
              <input
                id="lpTermsAgreedCheck"
                type="checkbox"
                checked={isTermsAgreed}
                disabled={isTermsAgreed}
                onChange={handleTermsAgreeChange}
              />
              <span className="checkmark checkmark-grey"></span>
              <div id="payplanProcessingLoader"></div>
            </label>
          </div>
          <div className="form-action">
            {!!params.showPayPlanSubmit && (
              <a className="cta" id="lpCreatePayPlanButton" href="#" onClick={handlePayPlanSubmitClick}>
                Submit payment plan
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
